import { fetch } from './fetch'
import api from './url'

export default {
    // 套餐管理
    PackageCreate: function (params) {
        return fetch({
            url: api.PackageCreate,
            method: 'post',
            data: params
        })
    },
    PackageUpdate: function (params) {
        return fetch({
            url: api.PackageUpdate,
            method: 'post',
            data: params
        })
    },
    PackageUpdatePrice: function (params) {
        return fetch({
            url: api.PackageUpdatePrice,
            method: 'post',
            data: params
        })
    },
    PackageList: function (params) {
        return fetch({
            url: api.PackageList,
            method: 'get',
            params: params
        })
    },
    PackageStatus: function (params) {
        return fetch({
            url: api.PackageStatus,
            method: 'post',
            data: params
        })
    },
    PackageChangeStatus: function (params) {
        return fetch({
            url: api.PackageChangeStatus,
            method: 'post',
            data: params
        })
    },
    PackDelete: function (params) {
        return fetch({
            url: api.PackDelete,
            method: 'post',
            data: params
        })
    },
    PackageDetail: function (params) {
        return fetch({
            url: api.PackageDetail,
            method: 'get',
            params: params
        })
    },
    // 套餐折扣
    PackSaleUpdate: function (params) {
        return fetch({
            url: api.PackSaleUpdate,
            method: 'post',
            data: params
        })
    },
    PackSaleList: function (params) {
        return fetch({
            url: api.PackSaleList,
            method: 'get',
            params: params
        })
    },
    PackSaleDelete: function (params) {
        return fetch({
            url: api.PackSaleDelete,
            method: 'post',
            data: params
        })
    },
    PackSaleDetail: function (params) {
        return fetch({
            url: api.PackSaleDetail,
            method: 'get',
            params: params
        })
    },
    // 产品套餐分配
    ProPackDistriUpdate: function (params) {
        return fetch({
            url: api.ProPackDistriUpdate,
            method: 'post',
            data: params
        })
    },
    ProPackDistriList: function (params) {
        return fetch({
            url: api.ProPackDistriList,
            method: 'get',
            params: params
        })
    },
    ProPackDistriDelete: function (params) {
        return fetch({
            url: api.ProPackDistriDelete,
            method: 'post',
            data: params
        })
    },
    // 区域配置
    ReginUpdate: function (params) {
        return fetch({
            url: api.ReginUpdate,
            method: 'post',
            data: params
        })
    },
    ReginList: function (params) {
        return fetch({
            url: api.ReginList,
            method: 'get',
            params: params
        })
    },
    ReginDelete: function (params) {
        return fetch({
            url: api.ReginDelete,
            method: 'post',
            data: params
        })
    },
    ReginDetail: function (params) {
        return fetch({
            url: api.ReginDetail,
            method: 'get',
            params: params
        })
    },
    // 套餐功能组
    PackFuncList: function (params) {
        return fetch({
            url: api.PackFuncList,
            method: 'get',
            params: params
        })
    },
    CouponList: function (params) {
        return fetch({
            url: api.CouponList,
            method: 'get',
            params: params
        })
    },
    CouponCreate: function (params) {
        return fetch({
            url: api.CouponCreate,
            method: 'post',
            data: params
        })
    }
}
