<template>
	<div class="container">
		<div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
			<i class="el-icon-back"></i>
			返回
		</div>
		<el-form :rules="rules" ref="form" :model="form" label-width="200px" style="width:600px">
			<el-form-item prop="product_model_code" label="所属产品型号">
				<el-select v-model="form.product_model_code">
					<el-option v-for="ele in modellist" :key="ele.code" :label="ele.name+'('+ele.model+')'" :value="ele.code">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="package_name" label="包名称">
				<el-input v-model="form.package_name"></el-input>
			</el-form-item>
			<el-form-item prop="version_code" label="版本号">
				<el-input v-model="form.version_code"></el-input>
			</el-form-item>
			<el-form-item prop="live_type" label="直播类型">
				<el-radio-group v-model="form.live_type" class="ml-4">
					<el-radio label="p2p" size="large">p2p</el-radio>
					<el-radio label="webrtc" size="large">webrtc</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="edit" label="已上传版本包">
				<el-input disabled type="textarea" v-model="uploadedKey"></el-input>
			</el-form-item>
			<el-form-item v-if="edit" label="MD5信息">
				<el-input v-model="form.md5"></el-input>
			</el-form-item>
			<el-form-item prop="status" label="未配置区域版本状态">
				<el-select v-model="form.status">
					<el-option label="开发版本" :value="1"></el-option>
					<el-option label="测试版本" :value="2"></el-option>
					<el-option label="上线版本" :value="3"></el-option>
					<el-option label="recall" :value="4"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item :label="edit?'重新上传':'上传版本包'" prop="key">
				<el-upload :on-change="handleChange" :action="s3url" :show-file-list="false" :http-request="fileUpload" @change="handelFileChange" :auto-upload="false">
					<el-button size="small" type="info" >选取文件</el-button>
				</el-upload>
				<el-progress :percentage="percentage" status="success"></el-progress>
			</el-form-item>
			<h4 style="margin-bottom:10px;">| 设置多语言版本日志</h4>
			<el-form-item v-for="(item,key) in logsitem" :label="'语言类型'" :key="key">
				<el-select v-model="item.code" placeholder="语言类型">
					<el-option v-for="ele in langueList" :key="ele.code" :label="ele.name" :value="ele.code">
					</el-option>
				</el-select>
				<el-input style="margin-top:10px" type="textarea" :rows="5" placeholder="请输入内容" v-model="item.log">
				</el-input>
				<el-button style="position: absolute;margin-left: 10px;" size="mini" type="warning" @click.prevent="removeLanguage(item)">删除</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="info" @click="addLanguage">新增多语言日志</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="Validate(onSubmit)">提交</el-button>
			</el-form-item>
		</el-form>

		<h4 v-if="regionshow" style="margin-bottom:10px;">区域状态</h4>
		<div v-if="regionshow" class="search" style="display:flex">
			<el-form ref="form" style="flex:1" :inline="true" :model="regionsearch" class="demo-form-inline">
			</el-form>
			<div>
				<el-button type="primary" size="small" @click="addModel">新建</el-button>
			</div>
		</div>
		<el-table v-if="regionshow" border v-bind:data="regionStatuslist">
			<el-table-column label="固件版本编号">
				<template v-slot="scope">
					<span>{{ scope.row.firmware_version_code }}</span>
				</template>
			</el-table-column>
			<el-table-column label="区域编号">
				<template v-slot="scope">
					<span>{{ scope.row.region_code }}</span>
				</template>
			</el-table-column>
			<el-table-column label="固件版本状态">
				<template v-slot="scope">
					<span>{{ changeStatus(scope.row.status) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="250px">
				<template v-slot="scope">
					<el-button type="primary" size="small" @click="editRegionStatus(scope.row.code)">修改</el-button>
					<el-button type="danger" size="small" @click="deleteSolid(scope.row.code)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="regionshow" @current-change="handleRegionCurrentChange" v-model:currentPage="regionsearch.page" :page-size="regionsearch.limit" layout="total, prev, pager, next"
			:total="regiontotal">
		</el-pagination>

		<!-- 新增修改 -->
		<el-dialog :title="regiontitle+'区域状态'" v-model="regiondialogFormVisible" width="600px">
			<el-form :model="regionform" :rules="regionrules" ref="regionform">
				<el-form-item prop="region_code" label="区域" label-width="150px">
					<el-select v-model="regionform.region_code">
						<el-option v-for="item in regionlist" :key="item.code" :label="item.name" :value="item.code">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="status" label="固件版本状态" label-width="150px">
					<el-select v-model="regionform.status">
						<el-option label="开发版本" :value="1"></el-option>
						<el-option label="测试版本" :value="2"></el-option>
						<el-option label="上线版本" :value="3"></el-option>
						<el-option label="recall" :value="4"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="regiondialogFormVisible = false">取 消</el-button>
					<el-button type="primary" v-if="!regionedit" @click="RegionValidate(addconfirm)">新增</el-button>
					<el-button type="primary" v-if="regionedit" @click="RegionValidate(editconfirm)">修改</el-button>
				</span>
			</template>
		</el-dialog>

	</div>
</template>

<script type="text/ecmascript-6">
	import util from '../../../../util/util'
	import {
		firmStatus
	} from '../../../../assets/js/dictionary'
	import api from '../../../../axios/pack'
	export default {
		name: '',
		mixins: [],
		components: {},
		props: {},
		data() {
			return {
				form: {
					code: '',
					product_model_code: '',
					package_name: '',
					package_size: '',
					version_code: '',
					key: '',
					md5: '',
					status: '',
					live_type: 'p2p',
					log: '',
				},
				rules: {
					product_model_code: [{
						required: true,
						message: '请输入所属产品型号',
						trigger: 'blur'
					}],
					package_name: [{
						required: true,
						message: '请输入包名称',
						trigger: 'blur'
					}],
					version_code: [{
						required: true,
						message: '请输入版本号',
						trigger: 'blur'
					}],
					status: [{
						required: true,
						message: '请输入当前版本状态',
						trigger: 'blur'
					}],
					key: [{
						required: true,
						message: '请上传版本包',
						trigger: 'blur'
					}]
				},
				contentCode: [],
				solid_code: '',
				logsitem: [],
				langueList: [],
				modellist: [],
				upPackage: {},
				s3url: '',
				percentage: 0, // 上传进度,
				edit: false,
				uploadedKey: '',
				// 区域状态
				regionshow: false,
				regionStatuslist: [],
				regionsearch: {
					page: 1,
					limit: 10,
					firmware_version_code: ''
				},
				regiontotal: 0,
				regionform: {
					code: '',
					firmware_version_code: '',
					region_code: '',
					status: '',
				},
				regionrules: {
					region_code: [{
						required: true,
						message: '请输入区域编号',
						trigger: 'blur'
					}],
					status: [{
						required: true,
						message: '请输入固件版本状态',
						trigger: 'blur'
					}],
				},
				regionedit: false,
				regiontitle: '',
				regiondialogFormVisible: false,
				regionlist: []
			}
		},
		computed: {},
		watch: {},
		methods: {
			update() {
				this.regionform.firmware_version_code = this.$route.params.code
				this.api.VersionStatusUpdate(this.regionform).then(res => {
					if (res.data.code == 200) {
						this.$message.success(this.regiontitle + '成功')
						this.regiondialogFormVisible = false
						this.RegionSearch()
						return
					}
					this.$message.error(this.regiontitle + '失败' + res.data.msg)
				})
			},
			addconfirm() {
				this.update()
			},
			editconfirm() {
				this.update()
			},
			RegionValidate: function(call) {
				this.$refs['regionform'].validate(res => {
					if (res && call) {
						call()
					}
				})
			},
			changeStatus(item) {
				return util.handleValueDisplay(firmStatus, item)
			},
			handleRegionCurrentChange() {
				this.regionsearch.page = val
				this.RegionSearch()
			},
			RegionSearch() {
				this.api.VersionStatusList(this.regionsearch).then(res => {
					if (res.data.code == 200) {
						this.regionStatuslist = res.data.data.data
						this.regiontotal = res.data.data.page_info.total
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			addModel() {
				this.regionform = {}
				this.regionedit = false
				this.regiontitle = '新增'
				this.regiondialogFormVisible = true
				this.$nextTick(() => {
					this.$refs.regionform.resetFields()
				})
			},
			GetRegion() {
				api.ReginList({
					page: 1,
					limit: 100,
				}).then(res => {
					if (res.data.code == 200) {
						this.regionlist = res.data.data.data
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			editRegionStatus(item) {
				this.regionform = {}
				this.regionedit = true
				this.regiontitle = '编辑'
				this.regiondialogFormVisible = true
				this.$nextTick(() => {
					this.$refs.regionform.resetFields()
				})
				this.api.VersionStatusDetail({
					code: item
				}).then(res => {
					if (res.data.code == 200) {
						this.regionform = res.data.data
						return
					}
					this.$message.error('获取详情失败' + res.data.msg)
				})
			},
			deleteSolid(item) {
				this.$confirm('是否删除该固件版本区域状态?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 删除请求
					this.api.VersionStatusDelete({
						code: item
					}).then(res => {
						if (res.data.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							})
							this.RegionSearch()
							return
						}
						this.$message.error('删除失败' + res.data.msg)
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消删除'
					});
				})
			},
			handleChange() {
				this.percentage = 0
			},
			GetPromodel() {
				this.api.ProductModelList({
					page: 1,
					limit: 200
				}).then(res => {
					if (res.data.code == 200) {
						this.modellist = res.data.data.data
						this.GetRegion()
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			GetLanguage() {
				this.api.LanguageList({
					page: 1,
					limit: 100
				}).then(res => {
					if (res.data.code == 200) {
						this.langueList = res.data.data.data
						this.GetPromodel()
						return
					}
					this.$message.error('语言获取失败' + res.data.msg)
				})
			},
			GetInfo() {
				this.api.FirmwareDetail({
					code: this.solid_code
				}).then(res => {
					if (res.data.code == 200) {
						this.form = res.data.data
						this.uploadedKey = this.form.key + '(' + this.form.package_size + ')'
						let logs = JSON.parse(res.data.data.log)
						for (var key in logs) {
							this.logsitem.push({
								code: key,
								log: logs[key]
							})
						}
						this.RegionSearch()
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			goback() {
				this.$router.push('/iot/version/solid')
			},
			Validate: function(call) {
				this.$refs['form'].validate(res => {
					console.log(this.form);
					if (res && call) {
						call()
					}
				})
			},
			onSubmit() {
				let logs = {}
				this.logsitem.forEach(item => {
					if (item.code) {
						logs[item.code] = item.log
					}
				})
				this.form.log = JSON.stringify(logs)
				this.api.FirmwareUpdate({
					code: this.form.code,
					product_model_code: this.form.product_model_code,
					package_name: this.form.package_name,
					package_size: +this.form.package_size,
					version_code: this.form.version_code,
					live_type: this.form.live_type,
					key: this.form.key,
					md5: this.form.md5,
					status: +this.form.status,
					log: this.form.log,
				}).then(res => {
					if (res.data.code == 200) {
						this.form = {
							code: '',
							product_model_code: '',
							package_name: '',
							package_size: '',
							version_code: '',
							key: '',
							md5: '',
							status: '',
							log: '',
						}
						this.logsitem = []
						this.percentage = 0
						this.$confirm('提交成功，是否返回列表页?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.goback()
						})
						return
					}
					this.$message.error('提交失败' + res.data.msg)
				})
			},
			addLanguage() {
				this.logsitem.push({
					name: '',
					code: ''
				});
			},
			removeLanguage(item) {
				var index = this.logsitem.indexOf(item)
				if (index !== -1) {
					this.logsitem.splice(index, 1)
				}
			},
			// 上传
			handelFileChange(event) {
				if (this.form.package_name==""&&this.form.product_model_code==""&&this.form.version_code=="") {
					this.Validate()
					return 
				}
				this.upPackage = event.raw
				this.GetPicturepresign()
			},
			// 预签名
			GetPicturepresign() {
				util.getFileMD5(this.upPackage, md5 => {
					let params = {
						content_length: this.upPackage.size,
						file_name: this.upPackage.name,
						product_model_code: this.form.product_model_code,
						type: 'hard',
						version_code: this.form.version_code,
						'content_type': this.upPackage.name.split('.')[1],
					}
					this.form.package_size = this.upPackage.size
					this.form.md5 = md5
					this.GetPreSign(this.upPackage, params)
				})
			},
			GetPreSign(file, params) {
				this.api.FirmwarePreSign(params).then(res => {
					if (res.data.code == 200) {
						this.s3url = res.data.data.put_url
						this.form.key = res.data.data.key
						this.fileUpload(file)
						return
					}
					this.$message.error('预签名失败' + res.data.msg)
				}).catch(err => {
					console.log(err)
				})
			},
			fileUpload(file) {
				if (this.form.package_name==""&&this.form.product_model_code==""&&this.form.version_code=="") {
					this.Validate()
					return 
				}
				
				
				let config = {
					headers: {
						'Content-Type': this.upPackage.name.split('.')[1],
						'X-Amz-Acl': 'public-read',
						'X-Amz-Server-Side-Encryption': "AES256"
					},
					onUploadProgress: progressEvent => {
						let val
						if (progressEvent.lengthComputable) {
							val = parseInt(((progressEvent.loaded / progressEvent.total) * 100).toFixed(0))
						}
						this.percentage = val
					}
				}
				var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
				let url = '/aws' + this.s3url.split(urlReg.exec(this.s3url)[0])[1]
				this.$axios.put(url, file, config).then(res => {
					if (res.status === 200) {
						this.$message.success('上传成功')
					}
				}).catch(err => {
					this.$message.error('上传失败:' + err)
				})
			},
		},
		filters: {},
		mounted() {
			this.solid_code = this.$route.params.code
			this.regionsearch.firmware_version_code = this.$route.params.code
			if (this.solid_code !== '0') {
				this.edit = true
				this.regionshow = true
				this.GetInfo()
			} else {
				this.edit = false
				this.regionshow = false
			}
		},
		created() {
			this.GetLanguage()
		}
	}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
	.upload-demo {
		float left
	}

	.el-progress {
		float right line-height 40px width 70%
	}
</style>
